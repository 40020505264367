@import './media-queries.scss';

.standard {
  h1,
  h2,
  h3 {
    font-family: "Avenir", sans-serif;
  }

  h4,
  h5,
  p,
  li,
  button {
    font-family: "Avenir", sans-serif;

  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  li {
    margin: 0;
  }
  &__link{
    text-decoration: none;
    color:initial;
    cursor: pointer;
  }
  &__back{
    display: flex;
    flex-direction: row;
    padding:20px;
    align-items: center;
    img{
      width:16px;
      border:1px solid black;
      padding:8px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  button {
    cursor: pointer;
  }
  input[type="text"], input[type="email"], input[type="password"] {
    font-size: 14px;
    font-family: "Raleway", serif;
    font-weight: bold;
}
  .pagination {
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px auto;
      padding-bottom: 20px;

      li {
        cursor: pointer;
        color: #5D21D2;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border:none;
        padding: 5px 0;
        margin: 0 5px;
        font-weight: bolder;

        a {
          padding: 5px 10px;
          color:#5D21D2;
        }

        a:focus {
          outline: none;
        }

        &:hover {
          background-color: #e6e6e6;

          color: #5D21D2;

          a {
            color:#5D21D2;;
          }
        }
      }

      .active {
        border:1px solid #5D21D2;;
        color: #5D21D2;;

        a {
          color: #5D21D2;
        }
      }

      .previous, .next {
        border: 1px solid grey;

      }
    }

  &__only-desktop{
    display: none;
  }
  @include tablet {

  }

  @include desktop {
    &__back{
      padding:40px 10%;
    }
    &__only-desktop{
      display: initial;
    }
    &__only-mobile{
      display: none;
    }
  }
}


._loading_overlay_overlay {
  height: 100vh;
}
