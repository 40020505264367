@import '../../resources/media-queries.scss';

.blog {
    &__banner {
        background:
            linear-gradient(90deg, #6F7CFF 33.5%, rgba(111, 124, 255, 0) 72%),
            url('/assets/blog.jpg');
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        z-index: 1;
        height: 250px;
    }
    &__date{
        text-align: left!important;
        font-size: 12px;
       padding-top: 4px;
       color: rgb(42, 38, 100);
       position: relative;
       right:30%;
       display: flex;
       flex-direction: row;
       img{
        width:12px;
        object-fit: contain;
       }
    }
    &__title{
        color:#fff;
        text-align: left;
        font-size: 36px;
        padding-top: 50px;
        padding-left: 40px;
        
    }

 

    &__image-div {

        img {
            border-radius: 10px;
        }

        position: relative;

    }

    &__single-article {
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {}
    }

    &__other-article-title {
        text-align: left;
        font-size: 16px !important;

    }

    &__read-link {
        text-decoration: none;
    }

    &__image-shadow {
        position: absolute;
        width: 100%;
        height: 100%;

        z-index: 54;
        top: 0;
        left: 0;
    }


    &__article-time {
        text-align: center;
        font-size: 10px;
        padding-top: 3px;
    }

    &__featured-section-info {
        padding: 20px;
    }

    &__time-box {
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
        justify-content: center;

        h4 {
            font-size: 12px;
            padding-top: 1px;
        }
    }

    &__circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: rgba(91, 95, 199, 1);
        ;
        margin: 5px;
    }

    &__featured-section-title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        padding: 10px 0 20px;
    }

    &__featured-section-details {
        text-align: center;
    }
    &__read-more{
        background-color: rgba(237, 19, 93, 1);
        color: #fff;
        border:none;
        border-radius: 36px;
        width:140px;
        height:40px;
        position: absolute;
        bottom:-20px;

    }
    &__read-featured {
        background-color: #5B5FC7;
        border: none;
        border-radius: 38px;
        padding: 16px 48px;
        width: 300px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        color: #ffff;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        line-height: 21px;

    }

    &__article-wrapper {
        padding: 20px 0 20px 20px;
        position: relative;
        // background-image: url('/assets/blog-1.png');
        object-fit: cover;
        background-repeat: no-repeat;
    }

    &__subtitle {
        font-size: 20px;
        padding: 10px 0;
        color: rgba(42, 38, 100, 1);
    }



    &__article-img {
        width: 150px;
        height: 150px;
    }

    &__article-title {
        font-size: 12px;
        text-align: center;
    }

   

    &__all-articles {
        padding: 20px;
        // background-image: url('/assets/blog-2.png');
        background-repeat: no-repeat;
    }

    &__article-container {
        width: 100%;
        position: relative;

    }

    &__article-whole {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 40px 0;
        border: 2px solid rgba(42, 38, 100, 1);
        border-radius: 10px;
    }

    &__article-only-info {
        width: 63%;
    }

    &__article-img {
        width: 100%;
        height: 230px;
        object-fit: cover;
        overflow: hidden;
        border-radius: 10px;
    }

    &__article-description {
        overflow: hidden;
        position: relative;
        line-height: 1.2em;
        max-height: 6em;

        margin-right: -1em;
        padding-right: 1em;
    }

    &__article-description:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;

    }

    &__article-description:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: white;
    }

    &__article-read-box {
        display: flex;
        flex-direction: row;
        margin-top: 10px;

        h4 {
            color: rgba(91, 95, 199, 1);
            ;
            font-size: 14px;
        }

        img {
            width: 14px;
            height: 12px;
            position: relative;
            top: 3px;
            left: 5px;
        }
    }

    &__article-time-box {
        justify-content: flex-start;
    }

    &__wrapper-article-title {
        font-size: 14px;
        padding: 10px 0;
    }

    &__newsletter {
        border: 1px solid rgba(91, 95, 199, 1);
        ;
        margin: 20px;
        padding: 40px;
    }

    &__newsletter-title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        padding-bottom: 10px;
    }

    &__newsletter-subtitle {
        text-align: center;
        font-size: 14px;
    }

    &__form {
        margin: 20px 0 0;
        display: flex;
        flex-direction: column;
    }

    &__field {
        border: solid 1px #c6c6c6;
        background-color: #fff;
        height: 42px;
        margin-bottom: 10px;
        text-indent: 5px;
    }

    &__field:focus {
        outline: none;
    }

    &__decoration {
        width: 130px;
        height: 300px;
        position: absolute;
        right: 0;
        top: -160px;
    }

    &__slider-shadow {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-box-shadow: inset -6px -33px 35px 9px rgba(255, 255, 255, 0.98);
        box-shadow: inset -6px -33px 35px 9px rgba(255, 255, 255, 0.98);
        z-index: 1;
        top: 0;
        left: 0;
    }

    &__slider-info {
        position: relative;
        bottom: 20px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 24%, rgba(255, 255, 255, 0.68) 64%, #fff);
        z-index: 4;
    }

    &__articles {
        padding: 20px;
    }
    &__article-title{
        padding:20px;
        color: rgba(42, 38, 100, 1);
        font-size: 18px;
    }
    &__description{
        font-size: 16px;
        font-weight: 400;
        color:rgba(42, 38, 100, 1);
        padding:0 20px 40px;

    }
    &__main-title{

    }

    @include tablet {
      &__articles-wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      &__article-whole{
        width:40%;
      }
    }

    @include desktop {
        &__banner{
            height:350px;
            position: relative;
        }

        &__title{
            z-index: 5;
            position: relative;
            font-size: 58px;
            line-height: 48px;
            padding-top: 175px;
            padding-left: 10%;;

        }
        &__articles {
            padding: 40px 10%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
        }
        &__article-container{
            width:30%!important;
        }
        &__article-whole{
            position: relative;
        }
        &__read-more{

        }


    }
}