@import '../../resources/media-queries.scss';

.fashion {
    
    &__banner {
        background: linear-gradient(90deg, #ED135D 0%, rgba(188, 60, 156, 0.794132) 32.5%, rgba(111, 124, 255, 0.47) 56%, rgba(214, 101, 133, 0) 100%), url('/assets/fashion.jpg');
        ;

    }
    &__yellow-font{
       color: rgba(251, 169, 35, 1)!important;
    }
    &__programa{
        background-image: url('/assets/fashion-workshop-img.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        margin:20px;
        height:300px;
    }
    &__programa-title{
        color:#fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 70px;
        h3{
            font-family: 'Dream Avenue', sans-serif!important;
            font-size: 24px;
            padding-bottom: 5px;
        }
        }
    &__see-more{
        display: flex;
        justify-content: center;
        align-items: center;
        margin:20px auto;
        position: relative;
        top:15%;
        background-color: rgba(111, 124, 255, 1);
        color: #fff !important;
        border-radius: 36px;
        border: none;
        height: 40px;
        width: 150px;
        font-size: 14px;
        margin-top: 20px;
        font-family: "Avenir", sans-serif;
    }

    @include desktop {
      
        &__banner-info {
            display: flex;
            flex-direction: row;
            padding-left: 10%;
            margin-top: 30px;

            h3 {
                color: #fff;
                font-size: 34px;
                padding-right: 10px;
            }
        }
        &__programa{
            height:600px;
            margin:40px 10%;
        }
        &__programa-title{
            position: relative;
            top:20%;
            h3{
                font-size: 46px;
            }
        }
        &__see-more{
            top:30%;
        }

        &__logo {
            width: 200px;
            height: 80px;
            position: relative;
            bottom: 20px;
        }
    }
}