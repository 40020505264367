@import '../../resources/media-queries.scss';

.hp {
    &__desktop-menu {
        display: none;
    }
    &__back{
        display: flex;
        flex-direction: row;
        padding:20px;
        align-items: center;
        img{
            width:18px;
            height: 17px;
            object-fit: contain;
        }
        h4{
            color: rgb(42, 38, 100);
        }
    }
    .yellow-span {
        color: rgba(251, 169, 35, 1) !important;
        font-weight: bold;
    }

    &__header {
        display: flex;
        flex-direction: row;
        padding: 20px;
        justify-content: space-between;

    }

    &__menu-icon {
        width: 12px;
        padding-left: 4px;
    }

    &__logo {
        width: 100px;
    }

    &__submenu {
        position: absolute;

        h5 {
            color: rgb(42, 38, 100);
            font-size: 16px;
            line-height: 28px;
            cursor: pointer;
            text-align: center;
            margin-left: 20px !important;
            transition: border 0.3s ease;
        }

        h5:hover {
            border-bottom: 1px solid rgb(42, 38, 100);
            cursor: pointer;
        }
    }

    &__banner {
        background-image: url('/assets/banner-img.png');
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
    }

    &__title {
        color: #fff;
        font-size: 40px;
        line-height: 48px;
        padding: 40px 20px 20px;
    }

    &__animawings {
        height: 170px !important;
        position: relative;
        bottom: 20px;
    }

    &__subtitle {
        color: #fff;
        font-weight: 500;
        padding: 0 20px;
    }

    &__banner-button {
        font-family: "Montserrat", sans-serif;
        margin-top: 20px;
        background-color: rgba(237, 19, 93, 1);
        color: #fff !important;
        border-radius: 36px;
        border: none;
        height: 50px;
        width: 200px;
        font-size: 17px;

        a {
            color: #ffff;
        }
    }

    &__benefits {
        background: linear-gradient(90deg, rgba(20, 33, 146, 0.24) 45.44%, rgba(0, 0, 0, 0) 90.27%);
        display: flex;
        flex-direction: column;
        margin: 20px;
    }

    &__benefit {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 15px auto;

        img {
            width: 20px;
            height: 20px;
            margin: 3px auto;
        }

        h3 {
            color: #ffff;
            padding-bottom: 5px;
            font-size: 18px;
        }

        h4 {
            color: #ffff;
            padding-bottom: 5px;
            font-size: 14px;
            font-weight: lighter;
        }
    }

    .yello-cadran {

        h2,
        h3,
        p {
            color: black;
        }
    }

    &__about {
        padding: 20px;
    }

    &__section-title {
        color: rgba(42, 38, 100, 1);
        font-size: 36px;
        padding-bottom: 15px;
        text-align: left;

        span {
            color: rgba(237, 19, 93, 1);

        }
    }

    &__section-description {
        color: rgba(42, 38, 100, 1);
        text-align: left;
        line-height: 25px;

        span {
            color: rgba(237, 19, 93, 1);
        }
    }

    &__meaning {
        background: rgba(213, 215, 255, 0.6);
        position: relative;
        margin: 30px 20px;
        padding: 60px 20px 20px;
        border-radius: 20px;
    }

    &__meaning-title {
        background: transparent;
        position: absolute;
        top: -20px;
        font-size: 30px;
    }

    &__meaning-box {
        text-align: left;
        margin-bottom: 10px;

        h4 {
            color: rgba(42, 38, 100, 1);
            font-size: 18px;
        }

        p {
            color: rgba(42, 38, 100, 1);
            padding-bottom: 10px;
            line-height: 24px;
        }

        img {
            width: 260px;
            height: 240px;
            object-fit: cover;
            border-radius: 20px;
            margin: 0 auto;
            display: flex;
        }
    }

    &__info-box {
        text-align: center;

        span {
            color: rgba(237, 19, 93, 1);
        }

        &__yellow {
            color: rgba(251, 169, 35, 1);
        }

        h2 {
            font-size: 26px;
        }

        p {
            font-size: 14px;
        }
    }

    &__program-section {
        padding: 20px;
    }

    &__program-box {
        background: rgba(237, 19, 93, 1);
        color: #ffff;
        text-align: left;
        padding: 30px 20px;
        margin: 20px 0;
        border-radius: 20px;

        h2 {
            font-size: 30px;
        }

        h3 {
            padding: 5px 0;
            font-weight: 500;
        }

        p {
            line-height: 24px;
            font-size: 14px;
        }
    }

    .yellow {
        background-color: rgba(251, 169, 35, 1);
    }

    .purple {
        background-color: rgba(42, 38, 100, 1);

    }

    &__form-section {
        padding: 40px 20px;
    }

    &__form-subtitle {
        color: rgba(42, 38, 100, 1);
    }

    &__form {
        display: flex;
        flex-direction: column;
        margin: 20px 0;

        input,
        textarea {
            border: 1px solid rgba(42, 38, 100, 1);
            border-radius: 10px;
            background-color: #fff;
            height: 46px;
            margin: 10px 0;
            font-family: "Avenir", sans-serif !important;
            font-weight: lighter !important
        }

        textarea {
            height: auto;
        }

        input:focus,
        textarea:focus {
            outline: none;
        }

        input::placeholder {
            text-indent: 3px;
            font-weight: 500;
        }
    }

    &__form-box {
        display: flex;
        flex-direction: column;
    }

    &__checkbox {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 20px;
        margin: 10px 0;

        h5 {
            text-align: left;
            padding-left: 5px;
            position: relative;
            top: 5px;
            color: rgba(42, 38, 100, 1);
            font-size: 12px;
        }
    }

    &__mentor-bio {
        text-indent: 5px;
        font-size: 16px;
    }

    &__anterior-programs {
        background-color: rgba(255, 92, 84, 1) !important;
        margin-left: 10px !important;

    }

    &__see-bio {
        cursor: pointer;
        border: 1px solid rgba(251, 169, 35, 1);
        width: 150px;
        display: flex;
        justify-content: center;
        margin: 5px auto !important;
        border-radius: 35px;
        padding: 3px;
    }

    &__form-submit {
        font-family: "Montserrat", sans-serif;
        margin: 20px auto;
        background-color: rgba(251, 169, 35, 1);
        color: #fff;
        border-radius: 36px;
        font-size: 17px;
        border: none;
        height: 50px;
        width: 200px;
    }

    &__pros-section {
        padding: 20px;
    }

    &__pro-title {
        width: 66% !important;

        h3 {
            font-size: 40px;
            color: rgba(42, 38, 100, 1);

        }
    }

    &__pros-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
    }

    &__pros-container h3 {
        text-align: center;
        margin-bottom: 20px;
    }

    &__pro {
        width: 32%;
        height: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        color: #fff;
        /* Text color for contrast */
        border-radius: 5px;
        /* Rounded corners */
        font-size: 12px;
        font-family: sans-serif !important;
        font-weight: light;
    }

    &__pro-blue {
        background: rgba(105, 117, 232, 1);
    }

    &__pro-red {
        background: rgba(255, 92, 84, 1);
    }

    &__pro-yellow {
        background: rgba(254, 212, 64, 1);
        color: black;
    }

    &__list-title {
        color: rgba(42, 38, 100, 1);
        text-align: left;
    }

    &__list {
        list-style-type: decimal;
        /* Remove default list styling */
        padding: 10px 20px;
        margin: 0;
        text-align: left;

    }

    &__list li {
        padding: 5px;
        margin-bottom: 10px;
        color: rgba(42, 38, 100, 1);
        font-size: 14px;
    }

    .yellow-span {
        color: rgba(251, 169, 35, 1) !important;
    }

    &__mentors-section {
        padding-left: 20px;
    }

    &__mentors {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 20px;
    }

    &__partner-slider {
        margin: 20px 0;

        img {
            width: 100px;
            height: 100px;
            object-fit: contain;
            border-radius: 10px;
        }
    }

    &__mentor {
        color: rgba(42, 38, 100, 1);
        text-align: center;
        margin-bottom: 20px;
        width: 100%;

        img {
            width: 90%;
            height: 90%;
            margin: 0 0 10px;
            border-radius: 20px;
            margin-bottom: 10px;
            object-fit: cover;
        }

        h4 {
            color: rgba(42, 38, 100, 1);

        }

        h5 {
            padding: 3px 0;
            color: rgba(42, 38, 100, 1);

        }
    }

    .bursa-button {
        margin-bottom: 40px;
    }

    &__impact-title {
        color: rgba(42, 38, 100, 1);
        text-align: left;
        padding-top: 10px;
    }


    &__impact-list {
        text-align: left;

        li {
            color: rgba(42, 38, 100, 1);
            padding: 5px 0;
        }
    }

    &__partners-section {
        padding: 20px;
    }

    &__partners {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        img {
            width: 20%;
            height: auto;
            object-fit: contain;
            margin: 0 auto;
        }
    }

    &__artesana {
        height: 50px !important;
        position: relative;
        bottom: 24px;
    }

    &__footer {
        background: rgba(42, 38, 100, 1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 40px 20px;
    }

    &__footer-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        margin: 8px auto;

        h4 {
            font-size: 16px;
            font-weight: lighter;
            color: #fff;
        }

        h5 {
            padding: 3px 0;
            font-size: 14px;
            font-weight: lighter;
            color: #fff;
        }
    }

    &__footer-logo {
        width: 140px;
        height: 70px;
        object-fit: contain;
    }

    &__footer-social {
        display: flex;
        flex-direction: row;
        margin: 10px auto;

        img {
            width: 20px;
            height: 20px;
            margin: 0 5px;
        }
    }

    &__faq-section {
        padding: 20px;
    }

    &__form-img {
        display: none;
    }

    &__faq-right {
        display: none;
    }

    &__domain-label {
        padding: 10px 0 5px;
    }

    &__form-submit.disabled {
        cursor: not-allowed;
        /* Change the cursor to indicate it's disabled */
        color: #888;
        /* Gray text color */
    }

    &__program-title {
        color: rgba(42, 38, 100, 1);
        padding-top: 20px;
        font-size: 20px;

    }

    &__oceanobe h5 {
        color: #ffff;
        padding-top: 15px;
    }

    &__programs {
        border: 2px solid rgba(42, 38, 100, 1);
        border-radius: 10px;
        margin: 10px 20px;
        padding-bottom: 40px;
    }

    &__programs-mentors {
        padding: 20px;
    }

    &__mentors-img {
        width: 100%;
        margin-top: 30px;
    }

    &__programs-banner {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    &__asus-logo {
        width: 200px;
    }

    &__programs-title {
        padding: 20px 0 10px;

        span {
            color: rgba(237, 19, 93, 1);

        }

        h3 {
            font-family: "Avenir", sans-serif;
            color: rgb(42, 38, 100);
            font-size: 20px;
            padding-bottom: 10px;

        }
    }

    &__programs-info {
        color: rgb(42, 38, 100);
        text-align: left;
        line-height: 25px;
        font-size: 16px;
        font-weight: lighter;
        padding: 0 20px 5px;
    }

    // .marquee {
    //     width: 100%;
    //     overflow: hidden;
    //     position: relative;
    // }

    // .marquee__inner {
    //     display: flex;
    //     width: max-content;
    //     animation: scroll 180s linear infinite;
    //     /* Longer duration for slower movement */
    // }

    // @keyframes scroll {
    //     from {
    //         transform: translateX(0);
    //     }

    //     to {
    //         transform: translateX(-94%);
    //     }
    // }

    // .hp__mentor {
    //     margin-right: 20px;
    // }

    &__apply-button {
        margin-bottom: -45px;
    }

    &__meaning-wrapper {
        padding-top: 20px;
    }

    &__skillup-programs {
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    &__single-program {
        border: 2px solid rgba(42, 38, 100, 1);
        border-radius: 10px;
        margin-bottom: 20px;
        padding-bottom: 30px;
        position: relative;
        margin-bottom: 60px;
        height: 650px;
        width: 93% !important;



        p {
            padding: 10px 0 20px;
        }

        button {
            font-family: "Montserrat", sans-serif;
            background-color: rgba(237, 19, 93, 1);
            color: #fff !important;
            border-radius: 36px;
            border: none;
            height: 50px;
            width: 200px;
            font-size: 17px;
            position: absolute;
            left: 20%;
            bottom: -3%;
        }
    }

    &__cadran-picture {
        width: 100%;
        height: 250px;
        object-fit: cover;
    }

    &__cadran-logo {
        width: 100px;
        margin-left: 10px;
    }

    &__program-title {}

    &__artesana-logo {
        width: 100px !important;
    }

    &__resources {
        background: rgba(213, 215, 255, 0.6);
        padding: 20px;

        button {
            font-family: "Montserrat", sans-serif;
            background-color: rgb(42, 38, 100);
            color: #fff !important;
            border-radius: 36px;
            border: none;
            height: 50px;
            width: 200px;
            font-size: 17px;
            margin-top: 20px;

        }
    }

    &__purple {
        color: rgb(105, 117, 232);
    }

    &__pink {
        color: rgb(237, 19, 93);
    }

    &__resources-text {
        h3 {
            color: rgb(42, 38, 100);
            font-size: 24px;
            font-weight: bold;

        }
    }

    &__see-anterior-program {
        background-color: rgba(255, 92, 84, 1) !important;


    }

    &__extra-info {
        h3 {
            color: rgb(42, 38, 100);
            padding-top: 20px;
            font-size: 20px;
        }
        p{
            text-align: center;
            padding-top: 10px;
            
        }
    }
    &__cost{
        background:url('/assets/price-image.jpg');
        padding:40px 20px;
        p{
            color:#ffff;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: left;
            padding:15px 0;
        }
    }
    &__cost-title{
        color: #fff;
        text-align: left;
    }
    .yellow-text{
        color: rgba(242, 204, 56, 1);

    }

    @include tablet {}

    @include desktop {
        &__header{
            position: relative;
        }
        &__back{
            position: absolute;
            z-index: 7;
            top:90px;
            left:9%;
        }
        &__banner{
            margin-top: 140px;
        }
        &__cost-title{
            font-size: 42px;
        }
        &__cost{
            padding:80px 10%;
            background-size: cover;
            p{
                width:80%;
                font-size: 20px;
                line-height: 24px;
            }
        }
        &__extra-info {
         
            p{
              width:50%;
              display: flex;
              margin:0 auto!important;
                
            }
        }
    
        &__see-program {
            width: 80%;
        }

        &__see-anterior-program {
            margin-left: 40px !important;


        }

        &__title {
            padding-top: 80px;
        }

        &__resources {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 40px 10%;
            padding: 40px 10%;
            align-items: center;
            border-radius: 20px;

            button {
                margin: 0;
            }
        }

        &__resources-text {
            text-align: left;
        }

        &__skillup-programs {
            display: block;

            padding: 40px 10%;
        }

        &__single-program {
            width: 80% !important;
            height: 660px;
            display: flex !important;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                padding: 0 20px 20px;
            }

            button {
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
                margin: 0 auto !important;
                left: 50%;
                transform: translate(-50%, -50%);
                top: 99%;
            }
        }

        &__cadran-picture {
            height: 450px;
        }

        &__program-title {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-left: 0 !important;

            img {
                height: 40px;
                object-fit: contain;
                position: relative;
                right: 30px;
            }

            h3 {
                font-size: 20px;
                min-width: 250px;
            }

        }

        &__glovo-boc {
            margin-left: 50px;
            padding-bottom: 20px;
        }

        &__glovo-img {
            height: 60px !important;
            position: relative;
            right: 80px;
        }

        &__cadran-logo {
            margin-left: 25px;
        }

        // .marquee {
        //     width: 80%;
        //     margin: 0 10%;
        // }

        // @keyframes scroll {
        //     from {
        //         transform: translateX(0);
        //     }

        //     to {
        //         transform: translateX(-84%);
        //     }
        // }


        &__programs-mentors {
            display: flex;
            justify-content: space-between;
            padding: 40px 10%;
        }

        &__programs {
            margin: 30px 0;
            padding-bottom: 40px;
            width: 50%;
            height: 700px;

        }

        &__mentors-img {
            width: 45%;
        }

        &__programs {}

        &__programs-banner {
            height: 350px;
            object-position: center;
        }

        &__programs-title {
            padding-bottom: 15px;
            display: flex;
            justify-content: center;
            align-items: center;

            h3 {
                font-size: 28px;
                padding-right: 10px;
                padding-bottom: 0;
            }
        }

        &__asus-logo {
            height: 40px;
        }

        &__programs-info {
            text-align: center;
        }

        &__desktop-menu {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 60%;


            h4 {
                color: rgba(42, 38, 100, 1);
                font-size: 16px;
                line-height: 30px;
                cursor: pointer;
            }
        }

        &__header {
            padding: 20px 10%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: fixed;
            top: 0;
            transition: top 0.3s;
            width: 80%;
            background-color: #fff;
            z-index: 5;
        }

        &__logo {
            width: 140px;
        }

        &__title {
            font-size: 48px;
        }

        &__subtitle {
            line-height: 35px;
            font-size: 22px;
        }

        &__banner {
            padding: 130px 0 50px;
        }

        &__banner-button {
            font-size: 16px;
        }

        &__banner-info {
            width: 30%;
            text-align: left;
            padding-left: 10%;
        }

        &__benefits {
            margin: 50px 10% 20px;
            display: flex;
            flex-direction: row;
            border-radius: 20px;
            padding: 20px 0;
        }

        &__about {
            padding: 40px 5% 40px 10% !important;
            width: 40%;
        }

        &__about-description {
            padding: 20px 0 20px;
        }

        &__meaning {
            margin: 40px 10%;
            padding: 40px;
            display: flex;
            flex-direction: row;
            padding-bottom: 0;

        }

        &__meaning-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            padding-top: 0;
        }

        &__meaning-box {
            width: 30%;

            h4 {
                padding-bottom: 10px;
            }

            img {
                margin: 0;
                display: block;
            }

            p {
                min-height: 110px;
            }
        }

        &__program-section {
            padding: 40px 10%;
        }

        &__program-wrapper {
            display: flex;
            justify-content: space-between;
        }

        &__program-box {
            width: 28%;
        }

        &__about-description {
            width: 40%;
        }

        &__more-about {
            width: 40%;

        }

        .right-aligned {
            display: flex;
            justify-content: flex-end;
        }

        &__pros-section {
            padding: 40px 10%;
            display: flex;
            flex-direction: row;
        }

        &__skill-title {
            width: 15%;
            margin-right: 100px !important;
        }

        &__skill-right {
            width: 70%;
        }

        .apply-button {
            margin-left: 0;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            text-decoration: none;
        }

        &__info-box {
            height: 100px;
        }

        &__list {
            display: flex;

            div:first-of-type {
                margin-right: 40px;
            }
        }

        &__form-img {
            display: block;
            width: 300px;
            height: 400px;
            margin-right: 50px;
        }

        &__form-section {
            padding: 40px 10%;
            display: flex;

        }

        &__form-right {
            width: 50%;
        }

        &__form-subtitle {
            text-align: left;
        }

        &__form-box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            input {
                width: 48%;
            }
        }

        &__checkbox {
            cursor: pointer;

            h5 {
                top: 0px;
            }
        }

        &__form-submit {
            margin: 20px 0 0;
        }

        &__mentors-section {
            padding: 40px 10%;
        }

        &__mentors {
            display: flex !important;
            flex-direction: row;
            justify-content: center; // Use center to avoid uneven spacing issues
            width: 100%;
            align-items: center;
            margin: 40px auto 20px;

            img {}
        }

        &__mentor {
            img {}
        }

        &__mentors-title {
            text-align: center;
        }

        &__meaning-impact {
            padding-bottom: 15px;

            img {
                display: block;
                width: 500px;
                height: 300px;
                object-fit: cover;
                border-radius: 20px;
                margin-left: 200px;
            }
        }

        .apply-button {
            margin-left: 0;
            display: flex;
            justify-content: flex-start;
        }

        &__impact-left {

            width: 40%;

            img {
                display: block;
            }
        }

        &__law-title {
            text-align: left !important;
            padding-top: 20px;
            padding-bottom: 20px;
        }

        &__partners-section {
            padding: 40px 10%;

            h2,
            p {
                text-align: center;
                margin: 0 auto !important;
                width: 40%;
            }

            p {
                margin-bottom: 25px !important;
            }
        }

        &__partners {
            width: 60%;
            align-items: center;
            margin: 40px auto 20px;
            justify-content: center;

            img {
                height: 75px;
            }
        }

        &__faq-section {
            padding: 40px 10%;

        }

        &__faq-container {
            display: flex;
        }

        &__faq-left {
            width: 50%;
            margin-right: 100px;
        }

        &__faq-right {
            display: block;
            position: relative;
            bottom: 50px;

            img:first-of-type {
                height: 200px;
            }

            img:nth-of-type(2) {
                height: 100px;
            }
        }

        &__footer {
            padding: 40px 10%;
            display: flex;
            flex-direction: row;

            h4 {
                padding-bottom: 15px;
                cursor: pointer;
            }

            h5 {
                padding: 5px 0;
                cursor: pointer;
            }
        }

        &__pro {
            width: 22%;
            padding: 0 5px;
        }

        &__pro-title {
            width: 36% !important;

            h3 {
                font-size: 42px;
                position: relative;
                top: 20px;
            }
        }


        &__meaning-title {
            font-size: 38px;
        }

        &__apply-button {
            margin-bottom: -65px;
        }


        &__animawings {
            height: 170px !important;
            bottom: -40px;
        }


        .bursa-button {
            margin-left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }





    }
}